import { ScrollingModule } from '@angular/cdk/scrolling';
import { ChangeDetectionStrategy, Component, Signal } from '@angular/core';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { RouterLink } from '@angular/router';

import { HeaderElementDirective } from '@core/shared/util';
import { OverviewModule, RootModuleNavComponent, SpinnerComponent } from '@core/ui';
import { OrganizationsFacade } from '@mp/kernel/organizations/data-access';
import { OrganizationListItem } from '@mp/kernel/organizations/domain';

@Component({
  selector: 'mpk-organizations-list',
  standalone: true,
  templateUrl: './organizations-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    RouterLink,

    MatMenuModule,

    ScrollingModule,
    OverviewModule,
    SpinnerComponent,
    HeaderElementDirective,
    RootModuleNavComponent,
  ],
})
export class OrganizationsListComponent {
  protected readonly organizations: Signal<OrganizationListItem[]> = this.facade.organizations;

  protected readonly organizationsLoaded: Signal<boolean> = this.facade.organizationsLoaded;

  constructor(private readonly facade: OrganizationsFacade) {
    this.fetchOrganizations();
  }

  fetchOrganizations(searchTerm?: string): void {
    this.facade.fetchOrganizations(searchTerm);
  }

  trackByFn(_index: number, { organizationId }: OrganizationListItem): string {
    return organizationId;
  }
}
